.breadcrumb {
  padding: .75rem 1.9rem;
  li {
    @extend .breadcrumb-item;
    text-transform: uppercase;

    &.last {
      @extend .active;
      text-transform: lowercase !important;
      color: $breadcrumb !important;
    }
  }
}