.section-employees {
  background-size: cover !important;
  height: 700px;

  @include media-breakpoint-down(md) {
    background-image: none !important;
    background-color: $blue-light !important;
    height: 560px;
  }
}

.employee {
  padding: 3rem 0 3rem 0;
  blockquote {
    padding: 1rem 0.85rem;
    &:before {
      left: -9px;
    }
    p {
      font-weight: normal;
    }
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: 300;
    @include fluid-type($min_width, $max_width, (26px/1.35), 32px, 1, 1);
  }

  h2 {
    margin-bottom: 1rem;
  }

  .btn {
    text-transform: uppercase;
    @include fluid-type($min_width, $max_width, (10px/1), 15px, 1, 1);
    background-color: transparent;
    color: $focus;
    border: 1px solid $focus;
    margin-bottom: 0.5rem;
    font-weight: 400;

    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    &:hover {
      background-color: $focus;
      color: $white;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100% !important;
  }
}

.employee-content {
  min-height: 690px;

  @include media-breakpoint-down(md) {
    min-height: 390px;
  }
}

.owl-dots {
  margin: -50px auto 0 auto !important;
  position: relative;

  .owl-dot {
    background-color: transparent;
    border: none;
    padding: 0.5px;
  }
}