nav {
  ul.pagination {
    margin: 1.5rem 0;
  }

  li.page-item {
    margin: 0 0.25rem;

    a {
      line-height: 18px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }

    &.previous, &.next {
      margin: 0 1rem;
    }

    &.previous {
      transform: rotate(45deg);
    }

    &.next {
      transform: rotate(225deg);
    }

    a.page-link {
      height: 38px;
      width: 38px;
      border-radius: 50% !important;
      position: relative;
      vertical-align: middle;

      .custom-arrow {
        &:before {
          transform: translate(1px, 5px) rotate(0deg);
        }
      }
    }

    &.disabled {
      a.page-link {
        .custom-arrow {
          &:after {
            background-color: $white;
          }

          &:before {
            background-color: $gray-300;
          }
        }
      }
    }
  }
}

//custom arrow
.custom-arrow {
  position: relative;
  display: flex;
  min-width: 10px;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-left: 2px solid $grayish;
    border-bottom: 2px solid $grayish;
    transform: rotate(45deg);
  }
}

a:hover {
  .custom-arrow {
    &:before {
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
    }
  }
}