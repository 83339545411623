.last-child {
    >.submenu {
        right: 0 !important;
    }
}

.nav-container {
    position: fixed;
    top: 0;
    left: 0;
}

.topbar-container {
    display: flex;
    justify-content: flex-end;
}

.nav-container,
.topbar-container {
    background-color: $white;
    width: 100%;
    z-index: 9;

    .navbar {
        position: relative;
        justify-content: space-between;
        display: flex;
        align-items: center;
        vertical-align: middle;

        .social {
            img {
                width: 25px;
                vertical-align: middle;
            }

            a:before {
                content: none !important;
            }
        }

        .navbar-brand {

            img {
                vertical-align: middle;
                max-height: 100px;
                max-width: 230px !important;
            }
        }

        .nav {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

ul.nav-desktop {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    z-index: 99;
    display: none;

    >li {
        display: inline-block;
        padding: 0.8rem !important;

        >a {
            position: relative;
            display: inline-block;
            @include fluid-type($min_width, $max_width, (15px/1.25), 20px, 1, 1);
            color: $body-color !important;
            font-weight: 400;
            line-height: 96px;
            text-decoration: none;
            transition: 0.3s;

            &:before {
                content: "";
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                top: 30px;
                background-color: $body-color;
                transition: 0.3s;

                &:last-child {
                    right: 0 !important;
                }
            }

            &:not(.btn) {
                padding: 0 0.8rem;
                color: $body-color;
            }
        }

        &.has-subitems {
            position: relative;

            .custom-arrow {
                display: none;
            }

            >a {
                &:before {
                    width: 100%;
                }
            }
        }

        //mega submenu
        >.submenu {
            background-color: $white;
            position: absolute;
            top: 100%;
            width: 300px;
            // left: 0;
            visibility: hidden;
            opacity: 0;
            transition: 0.3s;
            padding: 1rem 0.5rem;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                &.submenu-items {
                    margin-left: 1rem;
                    margin-right: 1rem;
                    width: 15rem;

                    @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

                    >li {
                        position: relative;
                        padding: 0.5rem 0;
                        break-inside: avoid;

                        a {
                            position: relative;
                            color: $body-color;
                            text-decoration: none;
                            transition: 0.3s;

                            &:before {
                                content: "";
                                height: 1px;
                                border-radius: 1px;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 0;
                                background-color: $body-color;
                                transition: 0.3s;
                            }
                        }

                        &.active,
                        &:hover {
                            a {
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        //active item
        &.active {
            >a {
                color: $focus;

                &:before {
                    width: 100%;
                }
            }
        }

        //open submenu
        &:hover {
            >a:not(.btn) {
                color: $focus;

                &:before {
                    background-color: $focus;
                    width: 100%;
                }
            }

            .submenu {
                visibility: visible;
                opacity: 1;
            }
        }

        //submenu active state
        &.active {
            >a:not(.btn) {
                color: $focus;

                &:before {
                    background-color: $focus;
                    width: 100%;
                }
            }
        }
    }
}

#page-wrapper {
    margin-top: 48px;
}

#nav-secundary {
    display: none;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0 1rem;

    >li {
        display: inline-block;

        >a {
            padding: 0 2rem;
            display: inline-block;
            font-weight: 600;
            line-height: 40px;
            text-decoration: none;
        }
    }
}

@include media-breakpoint-down(lg) {
    .navbar {
        margin-bottom: 0;
    }
}

@include media-breakpoint-up(sm) {
    .nav-container {
        .navbar {
            .navbar-brand {
                img {
                    max-width: 200px;
                    vertical-align: middle;
                }
            }
        }
    }

    ul.nav-desktop {
        li {
            >a {
                &:not(.btn) {}
            }
        }
    }

    #nav-secundary {
        display: flex;

        >li {
            >a:not(.btn) {
                padding: 0 2rem;
                line-height: 64px;
            }
        }
    }

    #page-wrapper {
        margin-top: 64px;
    }
}

@include media-breakpoint-up(lg) {
    body {
        .topbar-container {
            &:not(.sticky) {
                position: unset;
            }
        }
    }

    ul.nav-desktop,
    #nav-secundary {
        >li {
            &.has-subitems {
                position: relative;

                >a {
                    &:before {
                        width: 0%;
                    }
                }
            }
        }
    }

    .nav-container {
        &.sticky {
            box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1);

            .nav-topbar {
                height: 0;
                opacity: 0;
            }
        }
    }


    ul.nav-desktop {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >li {
            padding: 0.5rem 1rem;

            >a {
                &.btn {
                    padding: 0.5rem 2rem;
                }

                &:not(.btn) {
                    padding: 0.125rem 0;
                }
            }
        }
    }

    ul#nav-secundary {
        display: none;
    }
}

@include media-breakpoint-up(xl) {



    ul.nav-desktop {

        >li {
            padding: 1rem;

            >a {
                &.btn {
                    padding: 0.5rem 2rem;
                }

                &:not(.btn) {
                    padding: 0.125rem 0;
                }
            }
        }
    }

    #page-wrapper {
        margin-top: 110px;
    }
}

@include media-breakpoint-down(lg) {
    .nav-container {
        .navbar {
            .navbar-brand {
                img {
                    margin-bottom: 0 !important;
                }
            }

            &.container {
                padding-right: 0;
            }
        }
    }
}