.topbar-container {
  .navbar {
    .nav {
      z-index: 999;
      position: relative;

      .nav-desktop {
        .nav-item {
          vertical-align: middle;
          text-transform: uppercase;
        }

        .social {
          & ul.nav-desktop>li>a:before:hover {
            width: 0 !important;
          }
        }

        .nav-link {
          @include fluid-type($min_width, $max_width, (10px/1.15), 15px, 1, 1);
        }

        .submenu {
          height: 500px;
          overflow: auto;
          padding-bottom: 2rem;
          scrollbar-width: thin;

          .submenu-items {
            li {
              padding: 0;

              a {
                color: $body-color !important;

                &:hover {
                  font-weight: bold;
                  color: $focus !important;
                }
              }

              &:last-child {
                padding-bottom: 1rem !important;
              }
            }
          }
        }

        li {
          padding: 1rem 0.7rem 1rem 0.7rem;

          a {
            @include fluid-type($min_width, $max_width, (10px/1.15), 16px, 1, 1);

            &:before {
              top: 20px;
            }
          }
        }
      }
    }
  }
}
