.section-blog {
  text-align: center;
  padding-bottom: 5.5rem;

  a {
    text-decoration: none;
  }

  .blog {
    .blog-item {
      margin-top: 10px;

      > div {
        max-width: 85%;
        margin: 0 auto;
      }

      h3 {
        transition: all 0.2s ease;
      }

      &:hover {
        cursor: pointer;

        h3 {
          color: $breadcrumb;
        }
      }

      &_image {
        position: relative;
        width: 10rem;
        margin: 0 auto;
        z-index: 0;
        transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-color: $blue-light;
          height: 100%;
          width: 10rem;
          border-radius: 50%;
          z-index: -1;

          transform: rotate(16deg);
          transform-origin: bottom;
          transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
        }
      }

      @include media-breakpoint-up(md) {
        &_image {
          width: 10rem;
        }
      }
    }

    h3 {
      font-weight: 300;
    }

    .figure-circle {
      width: 170px;
      height: 170px;
      padding: 0;
      margin: 0 auto 1rem auto;
    }
  }
}

.all-items {
  &:hover {
    text-decoration: underline !important;
  }
}