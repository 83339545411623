.slider {
  .item {
    .item--left {
      width: 63%;
      float: left;

      border-right: 5px solid $white;
    }

    .item--right {
      width: 37%;
      float: left;

      border-left: 5px solid $white;
    }

    .figure {
      background-size: cover;
      height: 220px;
      width: 100%;
    }

    .item-content, .more {
      position: relative;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .item-content {
      padding: $spacer*2;
      color: $white;

      h2 {
        font-size: 30px;
        line-height: 28px;

        text-shadow: 3px 3px 7px rgba($black, 0.2);

        -webkit-transform: translate(-20px);
        transform: translate(-20px);

        -webkit-transition: all 1200ms 0ms ease;
        transition: all 1200ms 0ms ease;
      }
    }

    .more {
      a {
        position: relative;
        padding: $spacer/2 $spacer;
        border: 1px solid;
        display: inline-block;

        transition: all 200ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        text-decoration: none;
      }

      &:hover {
        a {
          background-color: $black;
          color: $white;
          border-color: $black;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .slider {
    .item {
      .figure {
        height: 150px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .slider {
    .item {
      .figure {
        height: 200px;
      }

      .item-content {
        h2 {

        }
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  .slider {
    .item {
      .figure {
        height: 300px;
      }

      .item-content {
        h2 {

        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .slider {
    .item {
      .figure {
        height: 400px;
      }

      .item-content {
        h2 {

        }
      }
    }
  }
}

body.loaded {
  .slider {
    .item {
      .item-content {
        h2 {
          -webkit-transform: translate(0px);
          transform: translate(0px);
        }
      }
    }
  }
}