.gallery {
  @include clearfix;
  overflow: hidden;

  a {
    display: inline-block;
    float: left;
    position: relative;
    width: 20%;
    text-decoration: none;
    border: none !important;

    figure {
      @extend .focushuidzorg-icons;
      @extend .focushuidzorg-icons-search;

      &:before {
        background-color: rgba($primary, 0.75);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        z-index: 1;
        opacity: 0;
      }
    }

    &:hover {
      figure {
        &:before {
          @include fluid-type($min_width, $max_width, (28px/1.2), 28px, 1.5/1.2, 1.5);
          opacity: 1;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.gallery {
  &.one-image {
    a {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }

      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }
  }

  &.two-image {
    a {
      width: 50%;
    }
  }

  &.three-image {
    a {
      width: 50%;

      @include media-breakpoint-up(sm) {
        width: 33.333%;
      }

      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }

    &.gallery--product {
      a {
        width: 50%;

        &:first-child {
          width: 100%;
        }
      }
    }
  }

  &.more {
    a {
      width: 50%;

      @include media-breakpoint-up(sm) {
        width: 33.333%;
      }

      @include media-breakpoint-up(md) {
        width: 25%;
      }

      @include media-breakpoint-up(lg) {
        width: 20%;
      }
    }

    &.gallery--product {
      a {
        width: 33.333%;

        &:first-child {
          width: 100%;
        }
      }
    }
  }
}