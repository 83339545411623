.section-treatment {
  background-size: 100%;
  background-position: bottom left;
  background-repeat: no-repeat;
}

.treatment {
  padding: 3rem 0;
  p {
    margin-top: 2rem;
  }

  .btn {
    text-transform: uppercase;
    @include fluid-type($min_width, $max_width, (10px/1), 15px, 1, 1);
    background-color: transparent;
    color: $focus;
    border: 1px solid $focus;
    margin-top: 0.5rem;
    font-weight: 400;

    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    &:hover {
      background-color: $focus;
      color: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .section-treatment {
    background-size: 80%;
  }
}