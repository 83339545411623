blockquote {
  position: relative;
  // background-color: #f3faf9;
  margin: 1rem 0;
  padding: 1rem 2rem;

  p {
    vertical-align: middle;
    align-items: center;
    display: flex;
    margin: 0;
    @include fluid-type($min_width, $max_width, (18px/1.2), 18px, 1.5, 1.5);
    font-weight: bold;
    line-height: 1.5;
  }

  &:before {
    margin: 0 0.5rem;
    padding: 0;
    color: $blue-dark;
    font-size: 62px;
    content: "“";
    position: absolute;
    top: -15px;
    left: 9px;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 1;
  }
}