.section-services {
  h2 {
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .services {
    margin-top: 2rem;
    vertical-align: middle;

    a {
      text-decoration: none;
    }

    @include media-breakpoint-down(lg) {
      &.row {
        margin: 0 !important;
      }
    }

    .service {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;

      hr {
        border-top: 1.5px solid $grayish;
      }

      div:nth-child(2) {
        margin-left: 1.75rem;
      }

      .image-wrapper {
        border: 1px solid #000;
        border-radius: 50%;
        width: 80px;
        min-width: 80px;
        height: 80px;
        display: flex;
        margin: auto;

        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;

        @include media-breakpoint-up(lg) {
          width: 100px;
          min-width: 100px;
          height: 100px;
        }
      }

      img {
        width: 55%;
        height: 80%;
        padding-left: 2px;
        padding-bottom: 5px;
        margin: auto;
        -webkit-transition: background-color .3s ease-out;
        -moz-transition: background-color .3s ease-out;
        -o-transition: background-color .3s ease-out;
        transition: background-color .3s ease-out;
      }

      &:hover {
        cursor: pointer;

        h3 {
          color: $breadcrumb;
        }

        .image-wrapper {
          background-color: $blue;
          border: 1px solid $blue !important;
        }
      }

      h3 {
        font-weight: normal;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      p {
        &:after {
          content: "›";
          margin-left: 8px;
        }
      }

      &:nth-child(odd) {
        padding-right: 2rem;
      }

      &:nth-child(even) {
        padding-left: 2rem;
      }

      @include media-breakpoint-down(lg) {
        &:nth-child(odd) {
          padding: 0 !important;
        }
        &:nth-child(even) {
          padding: 0 !important;
        }

        display: inline-block;

        .image-wrapper {
          margin-bottom: 1rem;
        }

        h3, p {
          text-align: center;
        }

        div:nth-child(2) {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
      }
    }
  }

}