$white:         #fff;
$grey:          #ccc;
$black:         #000;

$blue:          #D2E8E9;
$green-light:   #97d14f;
$blue-light:    #F3FAF9;
$blue-dark:     #21C2F8;
$breadcrumb:    #96b4b4;
$grayish:       #465555;
$red:           #c30c26;
$focus:         #1e3235;

$primary:       $blue;
$secondary:     $green-light;

$body-color:    $grayish;