.btn {
  display: inline-block;
  height: auto;
  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, 1.4, 1.4);
  color: $white;
  font-weight: 600;
  text-decoration: none;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  line-height: inherit;
  text-transform: uppercase;
  padding: 0.8rem 2rem;

  &-shadow {
    box-shadow: 0 0 15px 4px rgba(0,0,0,.10);
  }

  &-default {
    background-color: $black;

    &:hover {
      background-color: darken($black,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }
}
@include media-breakpoint-down(md) {
  .btn {
    width: auto !important;
  }
}
