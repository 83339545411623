/*!
 * Bootstrap Grid v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../settings/config";

@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../../../node_modules/bootstrap/scss/mixins/grid";

@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/utilities/display";
@import "../../../../node_modules/bootstrap/scss/utilities/flex";


@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/pagination";
@import "../../../../node_modules/bootstrap/scss/utilities";