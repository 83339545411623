* {
  color: $grayish;
  line-height: 1.8;
}

body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;

  @extend %default-font;

  font-family: 'IBM Plex Sans', sans-serif;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1490px !important;
  }
}

h3 {
  font-weight: 300;
}

h1, h2, h3, h4, h5 {
  color: #465555;
}

.sidebar {
  position: relative;
}

table, .table {
  border-spacing: 0;
}

.row {
  margin-right: 15px;
  margin-left: 15px;
}

.site-content {
  a:not(.page-link):not(.btn):not(.nav-link) {
    color: $green-light;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  ul:not(.specs):not(.pagination) {
    padding-left: 2.75rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;

      &:before {
        position: absolute;
        content: "--";
        margin-left: -1.25rem;
        color: $green-light;
      }
    }
  }
}

#page-wrapper {
  transition: 0.3s;

  &:before {
    content: "";
    background-color: $primary;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.padding-top--normal {
  padding-top: 1rem;
}

.padding-bottom--normal {
  padding-bottom: 1rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 3rem;
}

.padding-bottom--larger {
  padding-bottom: 3rem;
}

.margin-top--normal {
  margin-top: 1rem;
}

.margin-bottom--normal {
  margin-bottom: 1rem;
}

.margin-offset--normal {
  margin-top: -1rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 3rem;
}

.margin-bottom--larger {
  margin-bottom: 3rem;
}

@include media-breakpoint-up(md) {
  .padding-top--normal {
    padding-top: 3rem;
  }

  .padding-bottom--normal {
    padding-bottom: 3rem;
  }

  .padding-top--large {
    padding-top: 5rem;
  }

  .padding-bottom--large {
    padding-bottom: 5rem;
  }

  .padding-top--larger {
    padding-top: 12rem;
  }

  .padding-bottom--larger {
    padding-bottom: 12rem;
  }

  .margin-top--normal {
    margin-top: 3rem;
  }

  .margin-bottom--normal {
    margin-bottom: 3rem;
  }

  .margin-offset--normal {
    margin-top: -3rem;
  }

  .margin-top--large {
    margin-top: 5rem;
  }

  .margin-bottom--large {
    margin-bottom: 5rem;
  }

  .margin-offset--large {
    margin-top: -5rem;
  }

  .margin-top--larger {
    margin-top: 10rem;
  }

  .margin-bottom--larger {
    margin-bottom: 10rem;
  }

  .margin-offset--larger {
    margin-top: -10rem;
  }
}

.section-header-content {
  height: 250px;

  @include media-breakpoint-down(lg) {
    height: auto !important;
  }
}

a {
  color: $blue-dark;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}