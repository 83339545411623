.section-cta {
  background-color: #6d6e71;
  height: auto;
  text-align: center;
  padding: 3rem 0;

  .cta {
    vertical-align: middle;

    p {
      color: white !important;
      padding: 0;
      margin: 0;
      @include fluid-type($min_width, $max_width, (26px/1.6), 22.5px, 1.4, 1.4);
    }

    h3 {
      font-weight: bold;
      color: whitesmoke !important;
      @include fluid-type($min_width, $max_width, (26px/1.35), 26px, 1, 1);
    }

    .btn {
      margin-top: 1.2rem !important;
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
      line-height: 1.5;
      font-weight: 400;

      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;

      @include fluid-type($min_width, $max_width, (10px/1), 15px, 1, 1);

      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 2rem 0;
  }
}