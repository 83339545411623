footer {
  background-color: $blue-light;
  color: $grayish !important;

  padding: 2rem 0;

  h3 {
    font-weight: 300;
    margin-bottom: 1.5rem;
  }

  p {
    color: $grayish !important;
    line-height: 1.6;
    margin-bottom: 0;
  }

  .social {
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  .hover-blue {
    a {
      color: $grayish !important;
      text-decoration: none;

      &:hover {
        color: $blue-dark !important;
      }
    }
  }
}

.footer-wrapper {

  //max-width: 1490px;
  //margin: 0 6.5rem;
}

.footer-block {
  margin-bottom: 2rem;
  text-align: center;
  color: $black;

  a {
    color: $blue-dark;
    text-decoration: none;
    transition: 0.3s;

    &.highlighted {
      color: $blue-dark;
    }

    &:hover {
      color: $primary;
    }
  }

  p {
    margin: 0;
  }
}

.footer-logo {
  &__image {
    img {
      max-width: 220px;
    }
  }
}

@include media-breakpoint-up(md) {
  footer {
    padding: 5rem 0;
  }
  .footer-block {
    text-align: inherit;
  }
}

@include media-breakpoint-up(xl) {
  .footer-logo {
    text-align: right;
  }
}