// Breadcrumbs

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              0rem;
$breadcrumb-item-padding:           0.5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $primary;
$breadcrumb-divider:                quote(">");

$breadcrumb-border-radius:          $border-radius !default;

.breadcrumb {
  li a {
    color: $black !important;
    text-transform: lowercase;
    text-decoration: none;
  }

  .last {
    a {
      color: grey !important;
    }
  }
}