.section-header, .section-header-content {
  background-size: cover;
  background-position: center left;
  margin: auto;

  padding: 5rem 0 5rem 0;

  &.homepage {
    @include media-breakpoint-down(md) {
      padding: 0 0 3rem 0;
    }

    .container:first-child {
      @include media-breakpoint-up(lg) {
        background: none !important;
      }

      @include media-breakpoint-down(md) {
        h1, h3 {
          margin-left: 6.5rem;
        }
        h1 {
          margin-top: 2rem;
        }
        h3 {
          margin-bottom: 1.5rem;
        }
      }

      max-width: 100vw;
      background-size: cover;
      background-position: center left;
    }

    .container:nth-child(2) {
      margin-top: 2.5rem;
    }
  }

  h2, .h2 {
    margin-bottom: 1rem;
  }

  h3 {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1.1rem;
  }

  p {
    margin-bottom: 2.5rem;
  }

  .btn {
    text-transform: uppercase;
    @include fluid-type($min_width, $max_width, (10px/1), 15px, 1, 1);
    background-color: transparent;
    color: $grayish;
    border: 1px solid $grayish;

    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;

    &:hover {
      background-color: $grayish;
      color: $white;
    }
  }

  background-color: $primary;

  @include media-breakpoint-down(lg) {
    margin-top: 0;

    .header-content {
      width: 80%;
    }
  }
}

.section-header-content {
  background-position: center right !important;
  align-items: center;
  display: flex !important;

  h2, .h2 {
    font-weight: normal;
  }
}

@include media-breakpoint-down(sm) {
  .section-header-content {
    height: 125px !important;
  }
}

@include media-breakpoint-down(sm) {
  .section-header, .section-treatment {
    background-image: none !important;
  }

  .section-header {
    background-color: $blue;
  }

  .section-header-content {
    height: auto;
  }
}

