.section-vervolg {
  background-image: url("/images/home_acnetherapie2.jpg");
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: 50% !important;

  &.blue-background {
    background-color: $blue-light !important;
  }

  .vervolg-content {
    margin-bottom: 2rem;

    a {
      color: $blue-dark;
    }

    .list {
      padding-right: 2.5rem;

      a {
        color: black !important;
        text-decoration: none !important;
      }

      p {
        margin-bottom: 0;
      }

      .is-active {
        > a {
          font-weight: bold;
        }

        hr {
          border-top: 1.5px solid black;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      background: none;

      .contact-cta {
        p {
          margin: 0;
          padding: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .contact-cta {
        max-width: 45%;
        margin: auto;
      }
    }

    h1 {
      padding-bottom: 0.5rem;
    }

    h2 {
      font-weight: normal;
      line-height: 1.5rem;
    }

    h3, h4, h5 {
      @extend %default-font;
      padding: 1rem 0 0.3rem 0;
      font-weight: normal;
    }

    .btn {
      text-transform: uppercase;
      @include fluid-type($min_width, $max_width, (10px/1), 15px, 1, 1);
      background-color: transparent;
      color: $focus;
      border: 1px solid $focus;
      margin-top: 0.5rem;
      font-weight: 400;

      -webkit-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      transition: background-color 0.5s ease-out;

      &:hover {
        background-color: $focus;
        color: $white;
      }
    }
  }
}

.contact-cta {
  text-align: center;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}

@include media-breakpoint-down(xl) {
  .section-vervolg {
    background: none;
  }
}